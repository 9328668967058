import React from "react";
// import { Link } from "gatsby";

import Layout from "../../components/Layout";
// import Header from "../components/Header"
import Footer from "../../components/Footer";
import "../../css/product.scss";
import { Helmet } from "react-helmet";

import ProductDetails from "../../components/ProductDetails";

import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const Product2 = () => {
  const { language } = useI18next();
  const { t } = useTranslation();

  var linkProduct;
  {
    language == "ru"
      ? (linkProduct = "/products/apple-juice-300")
      : (linkProduct = "/en/products/apple-juice-300");
  }

  return (
    <Layout>
      <Helmet>
        <title>{t("seoTitleProduct2")}</title>
      </Helmet>
      {/* <Header /> */}
      <main>
        <div className="page page-product">
          <div className="page-title w-2/3 md:w-auto">
            <div className="page-title__name">
              <Link to="/products" className="page-title_link">
                {t("title")}
              </Link>
            </div>
          </div>
          <ProductDetails
            product={linkProduct}
            lang={language}
          ></ProductDetails>
        </div>
      </main>
      <Footer />
    </Layout>
  );
};

export default Product2;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["products", "common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
